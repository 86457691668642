import axios_api from '@/plugins/axios_api';
// import router from "@/router/router";

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
  GET_PROFILES,
  UPDATE_PROFILES,
  ADD_PROFILE,
} from '@/config/api-url-custom';
        

const state = {
  tweetprofiles : {
    profilelist  : [],
    updateTime    : null, //time last update
  }
};

const getters = {
 
}

const actions = {
  
  async get ({ commit, dispatch }, data) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let profiles = await axios_api.get(GET_PROFILES, data);
      // console.info("profiles:", profiles.data);
      
      const profileData = profiles.data.payload;
      commit('setProfiles', profileData);
      
      dispatch("loader/hideLoader", 0, { root: true });
      return true;
      
    } catch (err) {
      console.info("Error tweet.profiles get:", err);      
      commit('setProfiles', null);
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async set ({ commit, dispatch }, data) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let update = await axios_api.post(UPDATE_PROFILES, data);
      // console.info("profiles update:", update.data);
      
      const profileData = update.data.payload;
      commit('setProfiles', profileData);
      
      dispatch("loader/hideLoader", 0, { root: true });
      return true;
      
    } catch (err) {
      console.info("Error tweet.profiles set:", err);      
      commit('setProfiles', null);
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  async create ({ commit, dispatch }, data) {    
    try {
      dispatch("loader/showLoader", 15000, { root: true });
      
      let update = await axios_api.post(ADD_PROFILE, data);
      // console.info("profiles create:", update.data);
      
      const profileData = update.data.payload;
      commit('setProfiles', profileData);
      
      dispatch("loader/hideLoader", 0, { root: true });
      return true;
      
    } catch (err) {
      console.info("Error tweet.profiles create:", err);      
      commit('setProfiles', null);
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
   
  
};



const mutations = {
  setProfiles: (state, profileData) => {
    try {
      if (!profileData || profileData === null) {
        throw { msg : 'no profiles found' }
      }
      
      state.tweetprofiles = {
        profilelist   : profileData.profiles,
        updateTime    : new Date().getTime
      }
      
    } catch (err) {
      console.info("setProfiles err:", err);
      console.info("setProfiles err:", profileData);
      
      state.tweetprofiles = {
        profilelist   : [],
        updateTime    : new Date().getTime
      }
    }
  }
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};