import Vue from "vue";
import App from "@/App.vue";

import router from "@/router/router";
import store from "@/store";

// import { Navbar, Button, Field, Input, Icon, Loading, Checkbox, Select } from "buefy";
// Vue.use(Navbar);
// Vue.use(Button);
// Vue.use(Field);
// Vue.use(Input);
// Vue.use(Icon);
// Vue.use(Loading);
// Vue.use(Checkbox);
// Vue.use(Select);
import Buefy from "buefy";
import 'buefy/dist/buefy.css'
Vue.use(Buefy);
// require("@/assets/css/main.scss");

//https://github.com/eddiemf/vue-scrollactive
//https://eddiemf.github.io/vue-scrollactive/dist/#section-1
import VueScrollactive from 'vue-scrollactive'
Vue.use(VueScrollactive);

//https://github.com/michalsnik/aos
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
  once: true, // whether animation should happen only once - while scrolling down
});

require("./assets/css/main.scss")
require("./assets/css/custom.scss")


import { messaging }                          from '@/plugins/firebase/messaging';
import { onMessage }                          from "firebase/messaging";
onMessage(messaging, (payload) => {
  console.info('Receiving foreground message', payload.data);
  window.alert(payload.notification.body);
});
Vue.prototype.$messaging = messaging;


import web_lang from '@/lang/web_language'
Vue.prototype.$wl = web_lang;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
