<template>

    <form action="" method="get" autocomplete="on" class="container">
      <div class="columns">
        
        <div class="column is-8-desktop is-10-tablet is-offset-2-desktop is-offset-1-tablet">
          
          <div class="underline is-size-3 mb2rem ta-center c484848">
            {{ viewmode === 'pw_reset'  ? $wl('login_reset_pw_title')    : '' }}
            {{ viewmode === 'register'  ? $wl('login_register_title')    : '' }}
            {{ viewmode === 'login'     ? $wl('login_login_title')       : '' }}
            {{ viewmode === 'login2FA'  ? $wl('login_login2FA_title')    : '' }}
          </div>
          
          <div v-show="viewmode === 'register'" class="animated preFadeInLeft fadeInLeft">
            <b-field :label="$wl('login_login_input_name')" label-for="name" >
              <b-input type="text" v-model="frmdata.username" rounded value="" required
                autocomplete="name" id="name" name="name"
                maxlength="100">
              </b-input>
            </b-field>
          </div>
          
          <div v-show="viewmode === 'pw_reset' || viewmode === 'register' || viewmode === 'login'" class="animated preFadeInLeft fadeInLeft">
            <b-field :label="$wl('login_login_input_email')" label-for="email">
              <b-input type="email" v-model="frmdata.email" rounded value="" required
                autocomplete="email" id="email" name="email" 
                maxlength="150">
              </b-input>
            </b-field>
          </div>
          
          <div v-show="viewmode === 'register'" class="animated preFadeInLeft fadeInLeft">
            <b-field :label="$wl('login_login_input_invitetoken')" label-for="invite-token">
              <b-input type="text" v-model="frmdata.password_repeat" rounded value="" required
                id="invite-token" name="invite-token" 
                maxlength="100" aria-describedby="invite-token">
              </b-input>
            </b-field>
          </div>
          
          <div v-show="viewmode === 'register' || viewmode === 'login'" class="animated preFadeInLeft fadeInLeft">
            <b-field :label="$wl('login_login_input_pw')" label-for="password">
              <b-input type="password" v-model="frmdata.password" rounded value="" required
                :autocomplete="viewmode === 'register'? 'new-password' : 'current-password'" id="password" name="password" 
                password-reveal maxlength="100" aria-describedby="password-constraints"
                @keyup.native.enter="viewmode === 'login'? SubmitLogin() : ''">
              </b-input>
            </b-field>
          </div>
          
          <div v-show="viewmode === 'register'" class="animated preFadeInLeft fadeInLeft">
            <b-field :label="$wl('login_login_input_pwrepeat')" label-for="password-repeat">
              <b-input type="password" v-model="frmdata.password_repeat" rounded value="" required
                autocomplete="new-password" id="password-repeat" name="password-repeat" 
                password-reveal maxlength="100" aria-describedby="password-repeat">
              </b-input>
            </b-field>
          </div>
          
          
          <passwordValidation 
            class="mt10"
            v-show="viewmode === 'register'"
            :password="frmdata.password" 
            :passwordrepeat="frmdata.password_repeat"
            @passwordAccepted="SetAccepted" 
          />
          
          <div v-show="viewmode === 'login2FA'" class="animated preFadeInLeft fadeInLeft">
            <b-field :label="$wl('login_login_input_2fa')" label-for="2FA">
              <b-input type="number" v-model="frmdata.twoFA" rounded value="" required
                autocomplete="one-time-code" id="TwoFAInput" name="TwoFAInput" ref="TwoFAInput" 
                :autofocus="true" maxlength="6"
                @keyup.native.enter="viewmode === 'login2FA'? SubmitLogin2FA() : ''">
              </b-input>
            </b-field>
          </div>
          
          
          <div v-show="viewmode === 'login'" class="wa-checkbox field" style="margin-top:10px;">
            <b-checkbox v-model="frmdata.rememberme" size="is-medium">{{ $wl('login_check_remember_me') }}</b-checkbox>
          </div>
          
          <div v-show="viewmode === 'register'" class="wa-checkbox field" style="margin-top:10px;">
            <b-checkbox v-model="frmdata.termsaccept" size="is-medium">{{ $wl('login_check_accept_tc') }}</b-checkbox>
          </div>
          
          <div class="has-text-centered">
            <b-button v-show="viewmode === 'login'" @click="SubmitLogin()"  
                class="button is-fullwidth is-primary has-text-centered is-medium is-rounded" 
                style="justify-content:center; padding: 22px 40px; margin-top:30px;"
              >
              {{ $wl('login_button_login') }}
            </b-button>
            <b-button v-show="viewmode === 'login'" @click="SetViewMode('register')"  
                class="button is-fullwidth is-primary is-outlined has-text-centered is-medium is-rounded" 
                style="justify-content:center; padding: 22px 40px; margin-top:30px;"
              >
              {{ $wl('login_goto_register') }}
            </b-button>
            <b-button v-show="viewmode === 'register'" @click="SubmitRegister()"  
                class="button is-fullwidth is-primary is-outlined has-text-centered is-medium is-rounded" 
                style="justify-content:center; padding: 22px 40px; margin-top:30px;"
              >
              {{ $wl('login_button_register') }}
            </b-button>
            <b-button v-show="viewmode === 'pw_reset'" @click="SubmitPWReset()"  
                class="button is-fullwidth is-primary has-text-centered is-medium is-rounded" 
                style="justify-content:center; padding: 22px 40px; margin-top:30px;"
              >
              {{ $wl('login_button_pwreset') }}
            </b-button>
            <b-button v-show="viewmode === 'login2FA'" @click="SubmitLogin2FA()"  
                class="button is-fullwidth is-primary has-text-centered is-medium is-rounded" 
                style="justify-content:center; padding: 22px 40px; margin-top:30px;"
              >
              {{ $wl('login_button_login') }}
            </b-button>
            
            <div v-show="viewmode === 'login'" class="column ta-center forgot-password animated preFadeInLeft fadeInLeft">
              <span style="cursor:pointer" @click="SetViewMode('pw_reset')" >{{ $wl('login_goto_pwreset') }}</span>
            </div>
            <div v-show="viewmode === 'pw_reset' || viewmode === 'register'" class="column ta-center forgot-password animated preFadeInLeft fadeInLeft">
              <span style="cursor:pointer" @click="SetViewMode('login')" >{{ $wl('login_goto_login') }}</span>
            </div>
            <div v-show="viewmode === 'login2FA'" class="column ta-center forgot-password animated preFadeInLeft fadeInLeft">
              <span style="cursor:pointer" @click="TwoFaGotoLogin()" >{{ $wl('login_goto_login') }}</span>
            </div>
            
          </div>
          
          
          
          
        </div>
        
      </div>            
    </form>   
    
</template>

<script>

import passwordValidation from "@/components/public/general/password_validation.vue";
import { mapState } from 'vuex';

import Storage                from '@/helpers/storage';
import { COOKIE_LOGIN_REDIRECT } from '@/config/app.js';

export default {
    name: "comp_login",
    components: {
      passwordValidation
    },
    
    props: {
      pageViewMode        : {
        type: String,
        default: 'login',
      },
    },
    
    data() {
      return {
        viewmode          : 'login', // 'pw_reset/register/login/login2FA'
        
        frmdata : {
          email           : '',
          password        : '',
          rememberme      : true,
          
          username        : '',
          password_repeat : '',
          termsaccept     : false,
          
          twoFA           : '',
        },
        
        password_valid   : false,
      }
    },
    
    computed: {
      ...mapState('authentication', ['authentication/loggedInState','loggedInState']),
    },
    watch: {
      loggedInState() {
        // console.info("LoggedInState Changed:",  newLoggedInState);
        this.UpdateLoginAccessPage();
      },
    },
    
    mounted(){
      this.viewmode = this.pageViewMode;      
      this.$store.dispatch('authentication/updateLoginState', null);   
    },
    
    methods: {
      UpdateLoginAccessPage(){
        const loggedInState = this.$store.state.authentication.loggedInState;
        // console.info("UpdateLoginAccessPage AccessLevel:", AccessLevel);
        if (loggedInState.isLoggedIn === null || loggedInState.accessLevel <=0 ) {
          return;
        }
        
        if (parseInt(loggedInState.accessLevel) > 0) {
          const loginredirect = Storage.ReadObj(COOKIE_LOGIN_REDIRECT, true);
          if (loginredirect !== null) {
            Storage.Delete(COOKIE_LOGIN_REDIRECT);
            this.$router.push({path: loginredirect});
          } else {
            this.$router.push({name: 'userpage'});  
          }
          
        }
      },
      
      validateEmail() {
        if (this.frmdata.email.trim() === "") {
          this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('login_error_alert_title'), 
              message: this.$wl('login_input_error_email'), 
            }
          );
          return false;
        }
        return true;
      },
      
            
      validate2FA() {
        if (this.frmdata.twoFA === '' || this.frmdata.twoFA.trim === 0) {
          this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('login_error_alert_title'), 
              message: this.$wl('login_input_error_2fa'), 
            }
          );
          return false;
        }
        return true;
      },
      
            
      validatePassword() {        
        if (this.frmdata.password.trim() === "") {
          console.info("validatePassword?");
          this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('login_error_alert_title'), 
              message: this.$wl('login_input_error_password'), 
            }
          );
          return false;
        }
        return true;
      },
            
      validatePasswordRules() {
        if (this.password_valid !== true) {
          console.info("validatePasswordRules?");
          this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('login_error_alert_title'), 
              message: this.$wl('register_input_error_password_rules'), 
            }
          );
          return false;
        }
        return true;
      },
            
      validateTermsConditions() {
        if (this.frmdata.termsaccept !== true) {
          this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('login_error_alert_title'), 
              message: this.$wl('register_input_error_tc'), 
            }
          );
          return false;
        }
        return true;
      },
      
      async SubmitLogin(){
        if (!this.validateEmail() || !this.validatePassword()) return;
        
        let loginRtn = await this.$store.dispatch('authentication/login', this.frmdata);
        if (loginRtn === "2FA") {
          this.viewmode = 'login2FA';
          this.$refs.TwoFAInput.focus();
        }
      },
      
      
      async SubmitLogin2FA(){
        if (!this.validateEmail() || !this.validatePassword() || !this.validate2FA()) return;
        
        let loginRtn = await this.$store.dispatch('authentication/login', this.frmdata);
        if (loginRtn === "2FA") {
          this.viewmode = 'login2FA';
          this.$refs.TwoFAInput.focus();
        } else {
          this.frmdata.twoFA = '';
        }
      },
      
      async SubmitRegister(){
        if (!this.validateEmail() || !this.validatePassword() || !this.validatePasswordRules() || !this.validateTermsConditions()) return;
        
        let registerRtn = await this.$store.dispatch('authentication/register', this.frmdata);
        // console.info("SubmitRegister registerRtn:", registerRtn);
        if (registerRtn !== false) {
          this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('register_success_title'), 
              message: this.$wl('register_success_msg'), 
            }
          );          
          //this.viewmode = 'login';
          
          //Auto login?
          this.SubmitLogin();
        }
      },
      
      async SubmitPWReset(){
        if (!this.validateEmail()) return;
        
        let reqPWResetRtn = await this.$store.dispatch('authentication/passwordReset', this.frmdata);
        // console.info("reqPWResetRtn:", reqPWResetRtn);
        if (reqPWResetRtn !== false) {
          const ThaThis = this;
          this.$store.commit("dialog/setDialog", 
            { DialogType: 'alert', 
              title: this.$wl('login_error_alert_title'), 
              message: reqPWResetRtn.m, 
              onConfirm :() => { ThaThis.viewmode = 'login' }}, 
          );      
        }
      },
      
      SetViewMode(NewMode = 'login'){
        this.viewmode = NewMode;
      },
      
      TwoFaGotoLogin(){
        this.frmdata.twoFA = '',
        this.SetViewMode('login');
      },
      
      SetAccepted(Accepted){
        this.password_valid = Accepted;        
      },
      
      
    }
};
</script>

<style scoped>


    
</style>
