/* profile */
export const GET_PROFILES = '/tweet/profiles/get';
export const UPDATE_PROFILES = '/tweet/profiles/update';
export const ADD_PROFILE = '/tweet/profiles/create';

/* tweet post */
export const CREATE_POST = '/tweet/post/create';

/* admin testfunction */
export const GET_TEST_DATA = '/admin/testfunction';

/* SERVICE SERVER test */
export const GET_TEST2_DATA = '/testfeed/getdata';
export const GET_TESTJWT_DATA = '/testing/get_jwt_data';








