<template>
  <section class="wa-full-height">
    <div class="columns is-centered is-gapless is-marginless mt2rem mb2rem">
      <div class="column is-6-desktop is-6-tablet is-12-mobile">
        
      <!-- https://www.privacypolicyonline.com --> 
      
      <h1>About us</h1>

      <p>Our team started in the web hosting industry. After a few years, we discovered there were too many challenges to making a website. Traditional web hosting services were complicated and expensive to manage.</p>
      <br>
      <p>Seeing a need for simplicity, the Website.com site builder was born. We wanted to offer a website building platform that would require no coding skills or design experience, with everything needed to get published online. You can simply focus on creating an amazing website that reflects your brand. </p>
      <br>
      <p>After seeing an increased need for ecommerce solutions, we developed one of the only fully-featured, free and commission-free online store builders. As our global userbase grew, we expanded our editor with the ability to make multilingual websites. </p>
      
      

      
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Privacy",
  
  data() {
    return {
      
    }
  },
  
  mounted(){
    // console.info("mounted:...");
  },
};
</script>

<style scoped>
</style>