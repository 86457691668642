<template>
    <section>
              
      <!-- <b-loading :is-full-page="isFullPage" :active.sync="getLoaderState" :can-cancel="false"></b-loading> -->
      
    </section>
</template>

<script>


export default {
    name: "comp_dialogs",
    
    props: {},
    
    computed: {
      getDialogState() {
        return this.$store.getters['dialog/getDialogState'];
      },
    },
        
    data() {
      return {
        isFullPage : true,
      }
    },
    
    watch: {
      getDialogState(newDialog) {
        // console.info("newDialog:", newDialog);
        if (newDialog.DialogType === 'alert') {
          this.showAlert(newDialog)
        }
        if (newDialog.DialogType === 'info') {
          this.showInfo(newDialog)
        }
        if (newDialog.DialogType === 'alertError') {
          this.alertCustomError(newDialog)
        }
        if (newDialog.DialogType === 'confirm') {
          this.confirm(newDialog)
        }
      },
    },
    
    mounted(){},
    
    methods: {
      showAlert(payload) {
        this.WindowAlert(payload);
              
        // this.$dialog.alert({
        //     title: payload.title,
        //     message: payload.message,
        //     onConfirm: payload.onConfirm
        // });
      },
      
      showInfo(payload) {
        this.WindowAlert(payload);  
        
        // this.$dialog.alert({
        //     title: payload.title,
        //     message: payload.message,
        //     onConfirm: payload.onConfirm
        // });
      },
      
      alertCustomError(payload) {
        this.WindowAlert(payload);
        
        // this.$dialog.alert({
        //     title: payload.title,
        //     message: payload.message,
        //     type: 'is-danger',
        //     hasIcon: true,
        //     icon: 'times-circle',
        //     iconPack: 'fa',
        //     ariaRole: 'alertdialog',
        //     ariaModal: true,
        //     onConfirm: payload.onConfirm
        // })
      },
      
      confirm(payload) {
          this.WindowConfirm(payload);
          // this.$dialog.confirm({
          //     title: payload.title,
          //     message: payload.message,
          //     onConfirm: payload.onConfirm,
          //     cancelText: payload.cancleText,
          //     confirmText: payload.confirmText,
          // })
      },
      
      WindowAlert(payload) {
        // console.info("WindowAlert payload:", payload);
        window.alert(payload.message)
              
        if (payload.onConfirm) {
          payload.onConfirm(); 
        }        
      },
      
      WindowConfirm(payload) {
        // console.info("WindowConfirm payload:", payload);
        window.confirm(payload.message)
              .then((resp) => {
                if (resp === true && payload.onConfirm) {
                  payload.onConfirm(); 
                } else if (payload.cancleText){
                  payload.cancleText();
                }
        })
      }
    }
};
</script>

<style scoped>


    
</style>
