import axios from '@/plugins/axios_main';
import { API_ROOT } from '@/config/app.js';

const default_options = {
  withCredentials: true
}

export default new class axios_api {
  
  async get(url, data, extraOptions = {}){
    return  await axios({
                          ...{ method: 'GET', url: url, baseURL: API_ROOT, data: data },
                          ...default_options,
                          ...extraOptions
                        });
  }
  
  async post(url, data, extraOptions = {}){
    return  await axios({
                          ...{ method: 'POST', url: url, baseURL: API_ROOT, data: data }, 
                          ...default_options,
                          ...extraOptions
                        });
  }
  
  async put(url, data, extraOptions = {}){
    return  await axios({
                          ...{ method: 'PUT', url: url, baseURL: API_ROOT, data: data }, 
                          ...default_options,
                          ...extraOptions
                        });
  }
  
  createquery(query_params){
    return axios.createquery(query_params);
  }
  
};

