const SuperadminPage = () => import('@/views/superadmin/superadminpage')


 const Routes_SuperAdmin = [{
                              path: "/superadmin",
                              name: "SuperadminPage",
                              component: SuperadminPage,
                              // meta: { superadminNav: true },
                              meta: { userNav: true },
                            }
];

export default Routes_SuperAdmin;