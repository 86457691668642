import axios_api from '@/plugins/axios_api';

// import api_lang from '@/lang/api_language'
// import web_lang from '@/lang/web_language'

import {
    CREATE_POST,
} from '@/config/api-url-custom';

const state = {
  
};

const getters = {
 
}

const actions = {
  
  async create ({ dispatch }, data) {    
    try {      
      dispatch("loader/showLoader", 15000, { root: true });
      
      let createRtn = await axios_api.POST(CREATE_POST, data);
      console.info("createRtn:", createRtn);
      
      dispatch("loader/hideLoader", 0, { root: true });
      
      return createRtn.data.payload;
    } catch (err) {
      console.info("Error create post:", err);   
      
      dispatch("loader/hideLoader", 0, { root: true });
      return false;
    }
  },
  
  
  
  
};



const mutations = {
  
  
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};