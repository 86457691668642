<template>
  <div class="columns is-gapless has-background-light is-marginless">
      <div class="column is-hidden-mobile is-6-tablet is-7-desktop is-7-widescreen is-8-fullhd">
        <div class="hero is-fullheight full-page-image">
        </div>
      </div>

      <div class="column is-6-tablet is-5-desktop is-5-widescreen is-4-fullhd">
        <div class="hero is-fullheight">
          <div class="hero-heading">
            <div class="has-text-centered mt2rem">
                <router-link :to="{ name: 'Home' }">
                  <!-- <figure class="image is-128x128 is-inline-block">
                    <img class="login-logo" src="/img/logo.png" alt="Logo" height="56" width="56">
                  </figure> -->
                </router-link>
            </div>
          </div>
          <div class="hero-body">
            <AccountLogin :pageViewMode="pageViewMode" />
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import AccountLogin from "@/components/public/general/login.vue";

// import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  name: "Login",
  components: {
    AccountLogin
  },
  
  
  props: {
    pageViewMode : {
      type: String,
      default: 'login',
    },
  },

  data: function() {
    return {
      
    };
  },
  computed: {
    
  },
  watch: {
      
  },
  mounted(){

  },
  created() {
    
  },
  methods:{
    init(){
      document.body.className = document.body.className.replace("has-navbar-fixed-top","");
      //console.log('fixed-nav-removed');
    },
    

  },

};
</script>

<style scoped>
body{
 padding-top: 14.5rem !important;
}

.login-logo{
  height: 100%;
  width: auto;
}
.hero-body{
  align-items:flex-start !important;
}
.full-page-image {
  background: white url('/img/MedFunc_banner.50239e34.jpg') center / cover;
}
.button.HAweb{
  background-color: #7AB41D;
    color: #ffffff;
    border-width:3;
    border-color :#7AB41D;
}
.button.HAweb:hover{
    background-color: #7AB41D;
    color: #ffffff;
    border-width:3;
    border-color :#7AB41D;
}
</style>
