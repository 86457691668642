<template>
  <b-navbar v-if="$route.meta.userNav === true" class="has-background-white" close-on-click>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img class="login-logo" src="/img/logo.png" alt="Logo" height="56" width="56">
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ name: 'Home' }">Home</b-navbar-item>
      <!-- <b-navbar-item tag="router-link" :to="{ name: 'GetApp' }">App</b-navbar-item> -->
      <b-navbar-dropdown label="Over ons" class="is-hoverable">
        <b-navbar-item tag="router-link" :to="{ name: 'Aboutus' }">Aboutus</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Privacy' }">Privacy statement</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'TermsAndConditions' }">Terms and conditions</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Disclaimer' }">Disclaimer</b-navbar-item>
      </b-navbar-dropdown>
      
      
      <b-navbar-dropdown label="Admin" class="is-hoverable">
        <b-navbar-item tag="router-link" :to="{ name: 'Aboutus' }">Users</b-navbar-item>
      </b-navbar-dropdown>
      
      <b-navbar-item tag="router-link" :to="{ name: 'profile' }">Profile</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Contact' }">Contact</b-navbar-item>
      <!-- <b-navbar-item tag="router-link" :to="{ name: 'profile' }">Profile</b-navbar-item> -->
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <div class="buttons">
          
          <b-button class="button is-primary" @click="Logout">
            <strong>Logout</strong>
          </b-button>
          
        </div>
      </b-navbar-item>
      
      <!-- <AccountMenuProfile  :menuConfig="menuProfile" ></AccountMenuProfile> -->
      
    </template>
  </b-navbar>
</template>

<script>

import { mapState } from 'vuex';
// import AccountMenu          from "@/components/accountmenu.vue";

export default {
  name: "Nav",
  
    data() {
      return {
        isSignedIn: false
      };
    },
  
    computed: {
      ...mapState('authentication', ['authentication/loggedInState','loggedInState']),
    },
    watch: {
      loggedInState() {
        // console.info("LoggedInState Changed:",  newLoggedInState);
        this.UpdateLoginAccessPage();
      },
    },
    
    async mounted() {
      this.$store.dispatch('authentication/updateLoginState', null);
    },
    
    created() {
      
    },
    
    methods: {
      UpdateLoginAccessPage(){
        const loggedInState = this.$store.state.authentication.loggedInState;
        console.info("UpdateLoginAccessPage loggedInState:", loggedInState);
        if (loggedInState.isLoggedIn === null || loggedInState.accessLevel <=0 ) {
          this.isSignedIn = false;
          return;
        }
        
        if (parseInt(loggedInState.accessLevel) > 0) {
          this.isSignedIn = true;
        }
      },
      
      async Logout(){
        await this.$store.dispatch('authentication/logout', null);
        this.$router.push({path: '/'});
      },
      
      GotoRegistreren(){
        this.$router.push({ name: 'Login', params: { pageViewMode  : 'register', }, })
                    .catch(err => { console.info('router error:', err) });
      }
    }
};
</script>

<style scoped>
.login-logo{
  height: 100%;
  width: auto;
}
.navbar-item img {
  max-height: 3.5rem;
}

.wa-navigation-user {
  max-height: 3.5rem;
  box-shadow: 0 2px 0 0 #f5f5f5;
  border-bottom: 1px solid #e5e5e5;
}

.button.is-light.HAweb{
  background-color: #7AB41D;
    color: #ffffff;
    border-width:3;
    border-color :#7AB41D;
}

</style>