<template>
  <section>
    
   <Title :title="'Mijn gegevens'" :isCentered="true" class="mt1rem mb1rem is-centered"></Title>  
   
   <div class="columns m1rem is-multiline">
     <AccountTwofactor class="column is-4-desktop is-6-tablet is-12-mobile"/>
      
     <div class="column is-4-desktop is-6-tablet is-12-mobile" v-for="(tweetprofile, index) in tweetprofilelist" v-bind:key="index" >
      <!-- <Wallet :tweetprofile="tweetprofile" /> -->
     </div>
     <b-button @click="AddProfile">Add</b-button>
     
   </div>
   
  </section>
</template>

<script>
// @ is an alias to /src
import Title from "@/components/public/general/title.vue";
import AccountTwofactor from "@/components/backend/general/accounttwofactor.vue";
// import Wallet from "@/components/backend/general/wallet.vue";

import { mapState } from 'vuex';

export default {
  name: "Profiel",
  components: {
    Title,
    AccountTwofactor,
    // Wallet,
  },
  
  data() {
    return {
      tweetprofilelist : []
    }
  },
  
  computed: {
    ...mapState('tweetprofiles', ['tweetprofiles/tweetprofiles','tweetprofiles']),
  },
  watch: {
    tweetprofiles(newProfiles) {
      console.info("profiles Changed:",  newProfiles);
      this.tweetprofilelist = newProfiles.profilelist;
      // console.info("this.UserProfiles:", this.UserProfiles);
    },
  },
  
  mounted() {
    this.UpdateProfiles();
  },
  
  methods: {
    async UpdateProfiles(){
      await this.$store.dispatch('tweetprofiles/get', null);
    },
    async AddProfile(){
      await this.$store.dispatch('tweetprofiles/create', null);
    },
  }
};
</script>

<style scoped>
</style>