<template>
    <section>
      <Title :title="$wl('contact_us_title')" :isCentered="true" class="mt1rem mb1rem is-centered"></Title>  
      
      <div class="columns m1rem">
        
        <div class="column is-6 is-offset-3">
          <Card>       
            
            <b-field :label="$wl('contact_us_name')" label-for="name" >
              <b-input type="text" v-model="frmdata.name" rounded value="" required
                autocomplete="name" id="name" name="name"
                maxlength="100">
              </b-input>
            </b-field>
            
            <b-field :label="$wl('contact_us_email')" label-for="email">
              <b-input type="email" v-model="frmdata.email" rounded value="" required
                autocomplete="email" id="email" name="email" 
                maxlength="150">
              </b-input>
            </b-field>
            
            <b-field :label="$wl('contact_us_message')" label-for="email">
              <b-input type="textarea" v-model="frmdata.message" rounded value="" required
                autocomplete="email" id="email" name="email" 
                maxlength="15000">
              </b-input>
            </b-field>
                                      
            <ButtonsForm :confirmLabel="$wl('contact_us_submit')" class="mt2rem" @ConfirmBtnClicked="SubmitContactMsg"></ButtonsForm>          
          </Card>
        </div>
        
      </div>
      
    </section>
</template>

<script>

import Title from "@/components/public/general/title.vue";
import Card from "@/components/public/general/card.vue";
import ButtonsForm from "@/components/public/general/buttonform.vue";
import { mapState } from 'vuex';

export default {
  name: "contactus",
  
  components: {
    Title,
    Card,
    ButtonsForm,
  },
  
  data() {
    return {
      frmdata: {
        name              : '',
        email             : '',
        message           : '',
      },
    }
  },
  
  computed: {
    ...mapState('authentication', ['authentication/loggedInState','loggedInState']),
  },
  watch: {
    loggedInState(loggedInState) {
      // console.info("LoggedInState Changed:",  loggedInState);
      this.UpdateFrmData(loggedInState);
    },
  },
  
  async mounted() {
    this.$store.dispatch('authentication/updateLoginState', null);
  },
  
  methods: {
    UpdateFrmData(loggedInState){
      if (loggedInState.name !== null) {
        this.frmdata.name = loggedInState.name.toString();
      }  
      if (loggedInState.email !== null) {
        this.frmdata.email = loggedInState.email.toString();
      }  
    },
    
    validateEmail(){
      if (this.frmdata.email.trim() === "") {
        this.$store.commit("dialog/setDialog", 
          { DialogType: 'alert', 
            title: this.$wl('general_error_msg'), 
            message: this.$wl('contact_us_error_email'), 
          }
        );
        return false;
      }
      return true;
    },
    
    validateName(){
      if (this.frmdata.name.trim() === "") {
        this.$store.commit("dialog/setDialog", 
          { DialogType: 'alert', 
            title: this.$wl('general_error_msg'), 
            message: this.$wl('contact_us_error_name'), 
          }
        );
        return false;
      }
      return true;
    },
    
    validateMsg(){
      if (this.frmdata.message.trim() === "") {
        this.$store.commit("dialog/setDialog", 
          { DialogType: 'alert', 
            title: this.$wl('general_error_msg'), 
            message: this.$wl('contact_us_error_msg'), 
          }
        );
        return false;
      }
      return true;
    },
    
    async SubmitContactMsg(){
      if (!this.validateName() || !this.validateEmail() || !this.validateMsg()) return;
        
      let contactRtn = await this.$store.dispatch('contactus/sendMessage', this.frmdata);
      // console.info("contactRtn:", contactRtn);
      if (contactRtn !== false) {
        this.$store.commit("dialog/setDialog", 
          { DialogType: 'info', 
            title: this.$wl('general_msg_ok'), 
            message: this.$wl('contact_us_msg_received'), 
          }
        );
        this.frmdata.message = '';
        this.$router.push({path: '/'});
      }
      
    }
    
  }
  
};
</script>

<style scoped>
</style>