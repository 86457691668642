<template>
  <div id="app" class="is-fullheight-with-navbar">
    <publicNav></publicNav>
    <userNav></userNav>
    <!-- <msnav></msnav> -->
    
    <router-view :key="$route.fullPath" />
    <!-- <myFooter></myFooter> -->
    
    <myDialog></myDialog>
    <myLoader></myLoader>
        
  </div>
</template>

<script>
import publicNav from "@/components/publicnav";
import userNav from "@/components/usernav";
// import myFooter from "@/components/public/general/footer.vue";
import myLoader from "@/components/public/general/loader.vue";
import myDialog from "@/components/public/general/dialogs.vue";
// import msnav from '@/components/msnav.vue'

// import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: "app",
  components: {
    publicNav: publicNav,
    userNav: userNav,
    // myFooter : myFooter,   
    // msnav, 
    myLoader,
    myDialog,    
  },
  data () {
    return {
      // publicPath: process.env.VUE_APP_WEB_ROOT
    }
  },
  computed: {
    
  },
  watch: {
      
  },
  created(){
    
  },
  methods: {
      
  }

};
</script>


<style lang="css">
html {
  height: 100%;
}

body {
  min-height: 100%;
  height: auto;
  
  margin: 0;
  padding: 0;
  /* background-color: red;  */
   /* #f4f6fb; */
  font-family: 'Roboto';
}



@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Regular.eot");
    src: url("/fonts/Roboto-Regular.eot?#iefix") format('embedded-opentype'),
         url("/fonts/Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Roboto italic */

@font-face {
    font-family: "Roboto";
    src: url('/fonts/Roboto-Italic.eot');
    src: url('/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* Roboto bold */

@font-face {
    font-family: "Roboto";
    src: url('/fonts/Roboto-Bold.eot');
    src: url('/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

#app {
  margin: 0;
  padding: 0;
}

</style>
