import Vue from 'vue';
import Vuex from 'vuex';

import loader from '@/store/modules/general/loader';
import dialog from '@/store/modules/general/dialog';
import authentication from '@/store/modules/general/authentication';
import twofa from '@/store/modules/general/twofa';
import contactus from '@/store/modules/general/contactus';


/* custom */

import tweetprofiles from '@/store/modules/tweet/profiles';
import tweetpost from '@/store/modules/tweet/post';

import test from '@/store/modules/test';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loader,
    dialog,
    
    authentication,
    twofa,
    contactus,
    
    tweetprofiles,
    tweetpost,    
    
    test,    
  },
});
