<template>
  <div class="">

    <section class="mf-banner hero is-fullheight-with-navbar">
      <div class="hero-body">
        <div class="container">
          <h1 class="cFFFFFF" style="font-size:5rem">
             {{ bannerHeader }}
          </h1>
          <h2 class="cFFFFFF" style="font-size:3rem;">
            {{ bannerSubheader }}
          </h2>
        </div>
      </div>
    </section>

    <section class="columns is-centered is-multiline pb3rem">
      <div class="column is-12 ta-center pt3rem pb3rem">
          <span class="cf37c82 fw800 fs3rem ">What we do best</span>
      </div>    
      <div class="column is-10 pb3rem">
        <div class="columns">
          
            <div class="column is-4" data-aos="fade-up">
              <div class="br03 light-raised m1rem">
                  <div class="bc008489 cFFFFFF fw600 fs24 p1rem mb2rem ta-center" style="border-top-left-radius:0.3rem; border-top-right-radius:0.3rem">Coding</div>
                  <div class="i150 m-auto mb2rem">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.0333 14.8284L6.44751 16.2426L10.6902 12L6.44751 7.75733L5.0333 9.17155L7.86172 12L5.0333 14.8284Z"
                              fill="currentColor"
                            />
                            <path d="M15 14H11V16H15V14Z" fill="currentColor" />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2 2C0.895431 2 0 2.89543 0 4V20C0 21.1046 0.89543 22 2 22H22C23.1046 22 24 21.1046 24 20V4C24 2.89543 23.1046 2 22 2H2ZM22 4H2L2 20H22V4Z"
                              fill="currentColor"
                            />
                          </svg> 
                  </div>
                  <div class="ta-center p1rem pt0 c484848">
                  For the backend we code in Node.js, in the frontend we use vue.js.
                  </div>
              </div>
            </div>
            <div class="column is-4" data-aos="fade-up">
              <div class="br03 light-raised m1rem">
                  <div class="bc008489 cFFFFFF fw600 fs24 p1rem mb2rem ta-center" style="border-top-left-radius:0.3rem; border-top-right-radius:0.3rem">Consultancy</div>
                  <div class="i150 m-auto mb2rem">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4 1.5C2.89543 1.5 2 2.39543 2 3.5V4.5C2 4.55666 2.00236 4.61278 2.00698 4.66825C0.838141 5.07811 0 6.19118 0 7.5V19.5C0 21.1569 1.34315 22.5 3 22.5H21C22.6569 22.5 24 21.1569 24 19.5V7.5C24 5.84315 22.6569 4.5 21 4.5H11.874C11.4299 2.77477 9.86384 1.5 8 1.5H4ZM9.73244 4.5C9.38663 3.9022 8.74028 3.5 8 3.5H4V4.5H9.73244ZM3 6.5C2.44772 6.5 2 6.94772 2 7.5V19.5C2 20.0523 2.44772 20.5 3 20.5H21C21.5523 20.5 22 20.0523 22 19.5V7.5C22 6.94772 21.5523 6.5 21 6.5H3Z"
                              fill="currentColor"
                            />
                          </svg>
                  </div>
                  <div class="ta-center p1rem pt0 c484848">
                  We analyse the worflow and design more efficient ones.
                  </div>  
              </div>            
            </div>

            <div class="column is-4" data-aos="fade-up">
              <div class="br03 light-raised m1rem">
                  <div class="bc008489 cFFFFFF fw600 fs24 p1rem mb2rem ta-center" style="border-top-left-radius:0.3rem; border-top-right-radius:0.3rem">Hosting</div>
                  <div class="i150 m-auto mb2rem">
                          <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.738 19.9964C14.8186 19.9988 14.8994 20 14.9806 20C19.3989 20 22.9806 16.4183 22.9806 12C22.9806 7.58172 19.3989 4 14.9806 4C12.4542 4 10.2013 5.17108 8.73522 7H7.51941C3.92956 7 1.01941 9.91015 1.01941 13.5C1.01941 17.0899 3.92956 20 7.51941 20H14.5194C14.5926 20 14.6654 19.9988 14.738 19.9964ZM16.6913 17.721C19.0415 16.9522 20.9806 14.6815 20.9806 12C20.9806 8.68629 18.2943 6 14.9806 6C11.6669 6 8.98059 8.68629 8.98059 12H6.98059C6.98059 10.9391 7.1871 9.92643 7.56211 9H7.51941C5.03413 9 3.01941 11.0147 3.01941 13.5C3.01941 15.9853 5.03413 18 7.51941 18H14.5194C15.0691 18 15.9041 17.9014 16.6913 17.721Z"
                              fill="currentColor"
                            />
                          </svg>
                  </div>
                  <div class="ta-center p1rem pt0 c484848">
                  We are experienced in both AWS and dedicated server management.
                  </div>   
              </div>              
            </div>                                               
        </div>

      </div>
    </section>  
      
 

    <section class="hero mf-testimonial" style="height:400px;">
      <div class="hero-body">
        <div style="display:flex; flex-direction:column; margin:auto;">
        <div class="br03 b3white" style="display:flex; justify-content:row; align-items:stretch;">
            <div class="" style="display:flex; flex:1; align-items:flex-start;">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.13456 9H12.1346L10 14.6075H7L9.13456 9Z" fill="#ffffff" />
                  <path d="M14.1346 9H17.1346L15 14.6075H12L14.1346 9Z" fill="#ffffff" />
                </svg>               
            </div>
            <div class="fs22 mt2rem mb2rem cFFFFFF m-auto">Imagination is more important than knowledge. Knowledge is limited. Imagination encircles the world.</div>
            <div style="display:flex; flex:1; align-items:flex-end; ">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.13456 9H12.1346L10 14.6075H7L9.13456 9Z" fill="#ffffff" />
                  <path d="M14.1346 9H17.1346L15 14.6075H12L14.1346 9Z" fill="#ffffff" />
                </svg>               
            </div>            
        </div>
        <div class="ta-right mt1rem italic fs18 cFFFFFF">- Albert Einstein</div>
        </div>
      </div>      
    </section>  


    <!-- <section class="hero is-large is-white">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Fullheight title
          </h1>
          <h2 class="subtitle">
            Fullheight subtitle
          </h2>
        </div>
      </div>
    </section>  -->

  </div>
</template>

<script>
export default {
  name: 'About',
  data: function () {
    return {
      bannerHeader: 'Making life easier',
      bannerSubheader: 'For healthcare workers',
      introTitle : 'This is what we do',
      introText: 'We try to make your life easier by making the code do the work for you. Our company specialises in smart software solutions for health professionals.  '
    }
  },
}
</script>


<style scoped>
.mf-banner{
  background-image: url('~@/assets/images/MedFunc_banner.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  
}
.mf-testimonial{
  background-image: url('~@/assets/images/code.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  
}
.testimonial p::before {
    content: "\201D";
    font-family: Arial,sans-serif;
    color: #fff;
    font-weight: bold;
    font-size: 68px;
    line-height: 70px;
    position: absolute;
    left: -25px;
    top: 0;
}
</style>
